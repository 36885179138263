import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Layout from "./layout"
import Image from 'gatsby-image'
import layoutCSS from "./layout.css"
import { Navbar, Nav, NavDropdown , Button } from 'react-bootstrap';

const Header = ({ siteTitle }) => (
  <header
          style={{
      background: `#11273b`,
      // marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1120,
        height:`auto`,
      }}
    >

      <Navbar collapseOnSelect expand="lg" bg="" variant="dark" className="no-pad pad-t-5">
        <Navbar.Brand href="#home">
            <img style={{
              marginBottom: 10,
              // marginTop: 10,
              width: 155,
              height: 45,
            }} src="https://www.shoppre.com/img/images/shoppre-logo.png" alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {/*<NavDropdown title="Services" id="collasible-nav-dropdown">*/}
            {/*  <NavDropdown.Item href="#">Action</NavDropdown.Item>*/}
            {/*  <NavDropdown.Item href="#">Another action</NavDropdown.Item>*/}
            {/*  <NavDropdown.Item href="#">Something</NavDropdown.Item>*/}
            {/*  /!*<NavDropdown.Divider />*!/*/}
            {/*  <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
            {/*</NavDropdown>*/}
            {/*<Nav.Link href="https://www.shoppre.com/offers">Offers</Nav.Link>*/}
            {/*<Nav.Link href="https://www.shoppre.com/cheap-rates-international-shipping-cost-calculator">Shipping Rates</Nav.Link>*/}
            {/*<Nav.Link href="https://www.shopprecouriers.com/seller-courier-partner">Seller Partner</Nav.Link>*/}
          </Nav>
          <Nav>
            {/*<Nav.Link href="#deets">Login</Nav.Link>

            <Button variant="danger" onClick="window.location.href='https://w3docs.com';">Join Now</Button>*/}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export const query = graphql`
query {
  logoLight: file(relativePath: {eq: "logo-light.png"}) {
    childImageSharp {
      fluid(maxWidth: 2000) {
         ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default Header
