import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Layout from "./layout"
// import WhatsApp from 'gatsby-image'
import WhatsApp from "../components/whatsappicon"
import layoutCSS from "./layout.css"
import { Container, Row, Col } from 'react-bootstrap';
import { faWhatsappSquare } from "@fortawesome/free-solid-svg-icons"
import { FaWhatsappSquare } from "@fortawesome/react-fontawesome"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import WhatsAppicon from "./whatsappicon"


const Footer = ({ siteTitle }) => (
  <footer
          style={{
      background: `#11273b`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1120,
        height:"auto",
      }}
    >
      <Row className="pad-u-40 pad-l-20">
        <div class="col-md-4 col-sm-6 col-xs-12">
          {/*<img style={{*/}
          {/*  marginBottom: 10,*/}
          {/*  marginTop: 10,*/}
          {/*  width: 115,*/}
          {/*  height: 30,*/}
          {/*}} src="https://www.shoppre.com/img/images/shoppre-logo.png" alt="" />*/}
          {/*<p className="color-929ca5">*/}
          {/*  IndianShoppre LLP, <br/>*/}
          {/*  No. 182, 2nd Cross Road, 7th Main,<br/>*/}
          {/*  Koramangala 1st Block,<br/>*/}
          {/*  Bengaluru - 560034,<br/>*/}
          {/*  Karnataka, India*/}
          {/*</p>*/}
          {/*<p className="font-white">*/}
          {/*  <span><img src="https://www.shoppre.com/img/contactPhone.svg" alt=""/> &nbsp;&nbsp; +91-9880393291</span>*/}

          {/*  <br/>*/}
          {/*  <span>*/}
          {/*    <img className="whtsappicon" src="https://mcusercontent.com/8f7212d51aa9533c4788ccf05/images/34ad8bbf-5513-42b8-abfe-0b5f9556dcfa.png" alt=""/>*/}
          {/*    &nbsp;  +91-9880393291*/}
          {/*  </span>*/}
          {/*  <br/>*/}
          {/*  <span>*/}
          {/*    <img className="whtsappicon" src="https://mcusercontent.com/8f7212d51aa9533c4788ccf05/images/24d21828-1d2a-420d-8b8b-d459ba5c87b4.png" alt=""/>*/}
          {/*    &nbsp; support@shoppre.com*/}
          {/*  </span>*/}

          {/*</p>*/}
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12 text-center">
          <img style={{
            marginBottom: 10,
            marginTop: 10,
            width: 115,
            height: 30,
          }} src="https://www.shoppre.com/img/images/shoppre-logo.png" alt=""/>
          <p className="color-929ca5">
            IndianShoppre LLP, <br/>
            No. 182, 2nd Cross Road, 7th Main,<br/>
            Koramangala 1st Block,<br/>
            Bengaluru - 560034,<br/>
            Karnataka, India
          </p>
          <p className="font-white">
            <span><img src="https://www.shoppre.com/img/contactPhone.svg" alt=""/> &nbsp;&nbsp; +91-9880393291</span>

            <br/>
            <span>
              <img className="whtsappicon"
                   src="https://mcusercontent.com/8f7212d51aa9533c4788ccf05/images/34ad8bbf-5513-42b8-abfe-0b5f9556dcfa.png"
                   alt=""/>
              &nbsp;  +91-9880393291
            </span>
            <br/>
            <span>
              <img className="whtsappicon"
                   src="https://mcusercontent.com/8f7212d51aa9533c4788ccf05/images/24d21828-1d2a-420d-8b8b-d459ba5c87b4.png"
                   alt=""/>
              &nbsp; support@shoppre.com
            </span>

          </p>
        </div>
        <div class="col-md-4 col-sm-6 col-xs-12">
          {/*<p className="color-929ca5">*/}
          {/*  <span><a className="link-clr" href="https://www.shopprecouriers.com/medicine-international-courier-from-india">ShoppRe Medicine Courier</a></span><br/>*/}
          {/*  <span><a className="link-clr" href="https://www.shopprecouriers.com/best-international-courier-service-for-students">ShoppRe Documents Courier</a></span><br/>*/}
          {/*  <span><a className="link-clr" href="https://www.shopprecouriers.com/best-international-relocation-packers-movers">ShoppRe Packers & Movers</a></span><br/>*/}
          {/*  <span><a className="link-clr" href="https://ship.shoppre.com/prohibited-items-what-you-cannot-ship-internationally-from-india/">Prohibited Items</a></span><br/>*/}
          {/*  <span><a className="link-clr" href="https://www.shoppre.com/international-parcel-forwarding-india-online-shopping">ShoppRe Package Forwarding</a></span><br/>*/}
          {/*  <span><a className="link-clr" href="https://www.shoppre.com/international-shipping-gift-cards-vouchers">ShoppRe Shipping Voucher</a></span><br/>*/}
          {/*  <span><a className="link-clr" href="https://www.shoppre.com/seller-partner">ShoppRe for Sellers Shipping</a></span><br/>*/}
          {/*  <span><a className="link-clr" href="https://ship.shoppre.com/seller/">ShoppRe eCommerce Solution</a></span><br/>*/}
          {/*  <span><a className="link-clr" href="https://ship.shoppre.com/international-commercial-shipping-services-india/">Commercial/Bulk Shipments</a></span><br/>*/}
          {/*  <span><a className="link-clr" href="https://www.shoppre.com/countries">Countries Shipping Information</a></span><br/>*/}
          {/*</p>*/}
        </div>
      </Row>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export const query = graphql`
query {
  logoLight: file(relativePath: {eq: "logo-light.png"}) {
    childImageSharp {
      fluid(maxWidth: 2000) {
         ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default Footer
